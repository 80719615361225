import './App.css';
import ShopProducts from './ShopProducts';


function App() {    

  return (
      <div className="App">
        <header id='App-header' className="App-header">
        <ShopProducts />
      </header>
    </div>
  );
}

export default App;
