import React from "react";


class BannerImgs extends React.Component {
    constructor() {
      super()
      this.state = {
        bannerImgs: null,
        bannerLoad: false,
      }
        // setTimeout(() =>{
        //     document.getElementById("dot-0").classList.add("dot-active")
        // }, 1000);
    
        // setInterval(() =>{
        //     this.bannerArrow("right")
        // }, 5000);

        
        fetch("banner-imgs/banner-imgs.json")
            .then(response => response.json())
            .then(data =>{
                this.setState({
                    bannerImgs: data.imgs,
                    bannerLoad: true,
                    numOfImages: data.imgs.length,
                    currentImageRight: 0,
                    currentImageLeft: 0,
                    currentImage: 0
                })})

                
                };

        bannerArrow(dir){
            // let scroll = this.state.numOfImages * 2000
            if(dir === "right"){
                this.setState({currentImageRight: this.state.currentImageRight+2000, currentImageLeft: this.state.currentImageLeft+2000})
                if(this.state.currentImageRight+2000 < this.state.numOfImages * 2000){
                setTimeout(() =>{
                    if(window.innerWidth < 850){
                        document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageRight/5.5
                    }else{
                        document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageRight;
                    }
                this.setState({currentImage:this.state.currentImage + 1})
                setTimeout(() =>{document.getElementById(`dot-${this.state.currentImage}`).classList.add("dot-active");document.getElementById(`dot-${this.state.currentImage-1}`).classList.remove("dot-active")}, 50);
                }, 50);
                }else{
                    this.setState({currentImageRight: 0, currentImageLeft: 0})
                    setTimeout(() =>{
                        if(window.innerWidth < 850){
                            document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageRight/5.5
                        }else{
                            document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageRight;
                        }
                    this.setState({currentImage: 0})
                    setTimeout(() =>{document.getElementById(`dot-${this.state.currentImage}`).classList.add("dot-active");document.getElementById(`dot-${this.state.numOfImages-1}`).classList.remove("dot-active")}, 50);
                    }, 50);
                }
             
            }
            if(dir === "left"){
              

                this.setState({currentImageRight: this.state.currentImageRight-2000, currentImageLeft: this.state.currentImageLeft-2000})
                if(this.state.currentImageRight > 0){
                setTimeout(() =>{
                    if(window.innerWidth < 850){
                        document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageLeft/5.5
                    }else{
                        document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageLeft;
                    }
                this.setState({currentImage:this.state.currentImage - 1})
                setTimeout(() =>{document.getElementById(`dot-${this.state.currentImage+1}`).classList.remove("dot-active");document.getElementById(`dot-${this.state.currentImage}`).classList.add("dot-active")}, 50);
                }, 50);
                }else{
                    this.setState({currentImageRight: this.state.numOfImages * 2000 - 2000 , currentImageLeft: this.state.numOfImages * 2000 -2000})

                    setTimeout(() =>{
                        if(window.innerWidth < 850){
                            document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageLeft/5.5
                        }else{
                            document.getElementById("inner-banner-wrap").scrollLeft = this.state.currentImageLeft;
                        }
                    this.setState({currentImage:this.state.numOfImages - 1})
                    setTimeout(() =>{document.getElementById(`dot-${0}`).classList.remove("dot-active");document.getElementById(`dot-${this.state.numOfImages-1}`).classList.add("dot-active")}, 50);
                    }, 50);
                    
                }
            }
            setTimeout(() =>{
        }, 50);
        }   
        render() {
        return (
            <div id='banner-wrap' className="banner-wrap">
                    {/* <img src="imgs/banner-arrow.png" onClick={() => this.bannerArrow("left")} className="banner-left"></img> */}
                <div id="inner-banner-wrap" className="inner-banner-wrap">
                    {this.state.bannerLoad &&
                        this.state.bannerImgs.map((object, i) => {
                        return <img className='banner' onTouchEnd={() => this.bannerArrow("drag")} src={object} alt="" />
                    })}
                </div>
                    {/* <img src="imgs/banner-arrow.png" onClick={() => this.bannerArrow("right")} className="banner-right"></img> */}
                    {/* <div className="banner-dots">
                        {this.state.bannerLoad &&
                            this.state.bannerImgs.map((object, i) => {
                            return <div id={`dot-${i}`} className="dot"></div>
                        })}
                        
                    </div> */}
            </div>

    )}};

export default BannerImgs