import React from "react";
import waitForElementTransition from 'wait-for-element-transition';
import logo from './pc logo wight.png';
import BannerImgs from './banner';
class ShopProducts extends React.Component {

    constructor() {
      super()

      localStorage.setItem("bagItems", JSON.stringify({"products":[]}))
      // let bagItems = JSON.parse(localStorage.getItem("bagItems"));
      // if (bagItems === null){
      //   localStorage.setItem("bagItems", JSON.stringify({"products":[]}));
      // }

      this.state = {
        sku: null,
        img: null,
        name: null,
        price: null,
        loaded: false,
        subMenu: false,
        tagloaded:false,
        bagloaded:  false,
        subsubtagbar: false,
        subsubsubtagbar: false,
        allBagItems: JSON.parse(localStorage.getItem("bagItems")),
        sorting : "lowHigh"
      }


      
      
      fetch("shop.json")
      .then(response => response.json())
      .then(data =>{
            let bagItems = JSON.parse(localStorage.getItem("bagItems"));
            // for (let x = 0; x < bagItems.products.length; x++) {
            //   for (let i = 0; i < data.products.length; i++){
            //     if (bagItems.products[x].SKU === data.products[i].SKU){
            //       bagItems.products[x].price = data.products[i].price
            //       localStorage.setItem("bagItems", JSON.stringify(bagItems));
            //     }
            //   }
            // }
            let inStockX = data.products
            for (let i = 0; i < inStockX.length; i++){
              if (inStockX[i].inStock === false){
                inStockX.splice(i, 1)
              }
            }
            console.log(inStockX[470])
            bagItems = JSON.parse(localStorage.getItem("bagItems"));
              this.setState({
                sku: inStockX,
                subtags: data.subtags,
                filtertag: data.subtags,
                filtersku: data.products,
                loaded: true,
                bagloaded: false,
                allBagItems: JSON.parse(localStorage.getItem("bagItems"))
              })});
      
      
      
      
              
              setTimeout(function(){
                let bagItems = JSON.parse(localStorage.getItem("bagItems"));
                if (bagItems !== null){
                  let qty = 0
                  for (let i = 0; i < bagItems.products.length; i++) {qty = qty + bagItems.products[i].qty}
                  document.getElementById('num-of-items').innerText = qty;
                  let total = 0
                  for (let i = 0; i < bagItems.products.length; i++) {
                    total = Number(total) + Number(bagItems.products[i].price)*Number(bagItems.products[i].qty)
                  };
               
                  document.getElementById("cartTotal").innerText = `الاجمالي: ${total.toLocaleString('en-US', {})} ﷼`
                  document.getElementById("cartAmount").value = `${total.toFixed(2)}`
                  document.getElementById("cartDescription").value=JSON.stringify(bagItems.products)
                }
                
        
              
              }, 800);
              setTimeout(()=> this.tag("components")
              , 1000)}


    removebagitems(i,img,name,price,SKU) {
      let bagItems = JSON.parse(localStorage.getItem("bagItems"));
      if (bagItems.products[i].qty === 1){bagItems.products.splice(i, 1);}
      else{bagItems.products[i].qty = bagItems.products[i].qty-1}
      let qty = 0
      for (let i = 0; i < bagItems.products.length; i++) {qty = qty + bagItems.products[i].qty}
      document.getElementById('num-of-items').innerText = qty;
      localStorage.setItem("bagItems", JSON.stringify(bagItems));
      this.setState({allBagItems: JSON.parse(localStorage.getItem("bagItems")),})
      let bagItemsTemp = JSON.parse(localStorage.getItem("bagItems"));
      let total = 0
      for (let i = 0; i < bagItemsTemp.products.length; i++) {
        total = Number(total) + Number(bagItemsTemp.products[i].price)*Number(bagItemsTemp.products[i].qty)
      };
      document.getElementById("cartTotal").innerText=`الاجمالي: ${total.toLocaleString('en-US', {})} ﷼`
      document.getElementById("cartAmount").value=total.toFixed(2)
    };

    miniShoppingBagResetTimer(){
      clearTimeout(this.miniTimeOut);
      clearTimeout(this.miniTimeOutCheck);
      this.miniTimeOut = setTimeout(() =>{
        document.getElementById("mini-shopping-bag").classList.remove("mini-shopping-bag-active")
      }, 2500);
      this.miniTimeOutCheck = setTimeout(() =>{
        document.getElementById("checkmark").classList.remove("checkmark")
        document.getElementById("checkmark__circle").classList.remove("checkmark__circle")
        document.getElementById("checkmark__check").classList.remove("checkmark__check")
      }, 3000);
    }
    bagitems(i,img,name,price,SKU) {
      //ADD to cart 
      let bagItemsTemp = JSON.parse(localStorage.getItem("bagItems"));
      if(window.innerWidth > 850){
        document.getElementById("shopping-bag-wrap").style.zIndex="100"
        document.getElementById("shopping-bag-wrap").classList.add("user-interface-active")
        document.getElementById("App-header").classList.add("shop-main-active")
        document.getElementById("App-header").classList.add("shop-main-overflow")
        document.getElementById("sub-menu-img").src = "imgs/x.png"}
      else if(window.innerWidth < 850){

        clearTimeout(this.miniTimeOut);
        clearTimeout(this.miniTimeOutCheck);
        document.getElementById("mini-shopping-bag").classList.add("mini-shopping-bag-active")
        this.miniTimeOut = setTimeout(() =>{
          document.getElementById("checkmark").classList.add("checkmark")
          document.getElementById("checkmark__circle").classList.add("checkmark__circle")
          document.getElementById("checkmark__check").classList.add("checkmark__check")
        }, 500);
        this.miniTimeOut = setTimeout(() =>{
          document.getElementById("mini-shopping-bag").classList.remove("mini-shopping-bag-active")
        }, 3000);
        this.miniTimeOutCheck = setTimeout(() =>{
          document.getElementById("checkmark").classList.remove("checkmark")
          document.getElementById("checkmark__circle").classList.remove("checkmark__circle")
          document.getElementById("checkmark__check").classList.remove("checkmark__check")
        }, 3500);
      }
      if (bagItemsTemp == null){
        let bagItems = {"products":[{"SKU":SKU,"img":img,"name":name,"price":price,"qty":1}]};
        localStorage.setItem("bagItems", JSON.stringify(bagItems));
        document.getElementById('num-of-items').innerText = bagItems.products.length;
      }else{
        let bagItems = bagItemsTemp;
        let xx = false
        let qty = 0
        for (let i = 0; i < bagItems.products.length; i++) {
          if(bagItems.products[i].SKU === SKU){bagItems.products[i].qty = bagItems.products[i].qty+1;xx=true}
          qty = qty + bagItems.products[i].qty
        }
        if (xx === false){bagItems.products.push({"SKU":SKU,"img":img,"name":name,"price":price,"qty":1});qty = qty + 1}
        localStorage.setItem("bagItems", JSON.stringify(bagItems));
        
        document.getElementById('num-of-items').innerText = qty;
      }
      this.setState({
        allBagItems: JSON.parse(localStorage.getItem("bagItems")),
      })
      let bagItems = JSON.parse(localStorage.getItem("bagItems"))
      let total = 0
      for (let i = 0; i < bagItems.products.length; i++) {
        total = Number(total) + Number(bagItems.products[i].price)*Number(bagItems.products[i].qty)
      };
      document.getElementById("cartTotal").innerText = `الاجمالي: ${total.toLocaleString('en-US', {})} ﷼`
      document.getElementById("cartAmount").value = `${total.toFixed(2)}`
      document.getElementById("cartDescription").value=JSON.stringify(bagItemsTemp.products)
      };

    search(){
      let input = document.getElementById("search").value.toUpperCase();
      let filter = []
      for (let i = 0; i < this.state.sku.length; i++) {
        if(this.state.sku[i].name.toUpperCase().includes(input) === true){filter.push(this.state.sku[i])}};
        this.setState({filtersku : filter});
      };

      tag(tagName){
        if (tagName === "ALL"){
          //shows all the shop products
          //this.setState({filtersku : this.state.sku})
          let filter = [];
          for (let i = 0; i < this.state.sku.length; i++) {
            filter.push(this.state.sku[i])
          }
          this.setState({filtersku : filter});

        }else{
          //makes the sub tags bigger agin
          let subnodes = document.getElementById('sub-main-tag-bar').childNodes;
          for(var i=0; i<subnodes.length; i++) {if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "auto";subnodes[i].style.minWidth = "110px";}};
          
          //makes the sub tag bar go up
          if(this.state.subtags[0][tagName].tags !== false){
          document.getElementById("sub-main-tag-bar").classList.add("sub1-tag-bar-active");
          document.getElementById("main-tag-bar").scrollLeft = -10000;
          //makes the main tags small 
          this.timeOut = setTimeout(() =>{
            let nodes = document.getElementById('tag-bar').childNodes;
              for(var i=0; i<nodes.length; i++) {
                  if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "0px";nodes[i].style.minWidth = "0px";}
                }},500)
                this.setState({tagloaded:true})
          }
          //changing the shop content to the tag
          let filter = [];
          for (let i = 0; i < this.state.sku.length; i++) {if(this.state.sku[i].category === tagName){filter.push(this.state.sku[i])}};
          if (filter.length === 0){filter.push({"name": false})}
          this.setState({filtersku : filter,filterSuperTag : tagName});
          
          //creating the brand sub tags
          this.setState({filtertag : [], tagloaded: true});
          this.setState({tagSelection: tagName,filtertag1 : this.state.subtags[0][tagName].tags});

          }
      };

    subMainTagBar(tagName){
      if (tagName === "◀") {
        clearTimeout(this.timeOut);
        //makes the sub tag bar go down
        document.getElementById("sub-main-tag-bar").classList.remove("sub1-tag-bar-active");
        document.getElementById("main-tag-bar").scrollLeft = -10000;
        
        //makes the sub tags small after going down
        setTimeout(() =>{
        let subnodes = document.getElementById('sub-main-tag-bar').childNodes;
          for(var i=0; i<subnodes.length; i++) {
              if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "0px";subnodes[i].style.minWidth = "0px";};
              this.setState({subsubtagbar:false,subsubsubtagbar:false});
            }},500)

        //makes the main tags big agin
        let nodes = document.getElementById('tag-bar').childNodes;
        for(var i=0; i<nodes.length; i++) {
            if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "auto";nodes[i].style.minWidth = "110px";}};
      }else{
        
        if(tagName === "صمم جهازك الخاص"){window.open("https://pcmod-sa.com/pc-build-form/", "_self");}else{
        //makes the sub tags bigger agin
        let subnodes = document.getElementById('sub-tag-bar').childNodes;
        for(var x=0; x<subnodes.length; x++) {if (subnodes[x].nodeName.toLowerCase() === 'button') {subnodes[x].style.width = "auto";subnodes[x].style.minWidth = "110px";}};
        
        //makes the sub tag bar go up
        if(this.state.subtags[0][this.state.filterSuperTag][tagName].brand !== false){
          document.getElementById("sub-tag-bar").classList.add("sub-tag-bar-active");
          document.getElementById("main-tag-bar").scrollLeft = -10000;
          this.timeOut = setTimeout(function(){
            document.getElementById("back-arrow").classList.remove("back-arrow")
            let subnodes = document.getElementById('sub-main-tag-bar').childNodes;
            for(var i=0; i<subnodes.length; i++) {
                if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "0px";subnodes[i].style.minWidth = "0px";};
              }},500);
              this.setState({tagloaded:true})
        }
        //changing the shop content to the tag
        let filter = [];
        for (let i = 0; i < this.state.sku.length; i++) {if(this.state.sku[i].tag === tagName){filter.push(this.state.sku[i])}};
        if (filter.length === 0){filter.push({"name": false})}
        this.setState({filtersku : filter,filterMainTag : tagName});
        
        //creating the brand sub tags
        this.setState({filtertag : [], tagloaded: true});
        this.setState({tagSelection: tagName,filtertag : this.state.subtags[0][this.state.filterSuperTag][tagName].brand});
        }}
    }

    subBar(tagName){
      if (tagName === "◀") {
        clearTimeout(this.timeOut);
        //makes the sub tag bar go down
        document.getElementById("sub-tag-bar").classList.remove("sub-tag-bar-active");
        document.getElementById("main-tag-bar").scrollLeft = -10000;
        
        //makes the sub tags small after going down
        setTimeout(() =>{
        let subnodes = document.getElementById('sub-tag-bar').childNodes;
          for(var i=0; i<subnodes.length; i++) {
              if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "0px";subnodes[i].style.minWidth = "0px";};
              this.setState({subsubtagbar:false,subsubsubtagbar:false});
            }},500)

        //makes the main tags big agin
        let nodes = document.getElementById('sub-main-tag-bar').childNodes;
        document.getElementById("back-arrow").classList.add("back-arrow")
        for(var i=0; i<nodes.length; i++) {
            if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "auto";nodes[i].style.minWidth = "110px";}};
      }else{
        
        //makes the main bar go to the left
        if(this.state.subtags[0][this.state.filterSuperTag][this.state.tagSelection].gen[tagName] !== false){
        document.getElementById("sub-sub-tag-bar").classList.add("sub-sub-tag-bar-active");
        document.getElementById("main-tag-bar").scrollLeft = -10000;
        //makes the main tags small
        this.timeOut = setTimeout(function(){
        let nodes = document.getElementById('sub-tag-bar').childNodes;
        for(var i=0; i<nodes.length; i++) {
            if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "0px";nodes[i].style.minWidth = "0px";};
          }},500);
          this.setState({subsubtagbar:true})
        }
        //changing the shop content according to tha sub tag
        let filter = []
        for (let i = 0; i < this.state.sku.length; i++) {if(this.state.sku[i].brand === tagName && this.state.sku[i].tag === this.state.filterMainTag){filter.push(this.state.sku[i]);}};
        if (filter.length === 0){filter.push({"name": false})}
        this.setState({filtersku : filter});this.setState({filterbrand : tagName});
        //save the tag Selection
        this.setState({filtersubsubtagbar : this.state.subtags[0][this.state.filterSuperTag][this.state.tagSelection].gen[tagName],brandSelection: tagName});

        //makes the sub sub tags small after going down
        let subnodes = document.getElementById('sub-sub-tag-bar').childNodes;
        for(var x=0; x<subnodes.length; x++) {
            if (subnodes[x].nodeName.toLowerCase() === 'button') {subnodes[x].style.width = "auto";subnodes[x].style.minWidth = "110px";}
        };

        
    }}

    subsubBar(tagName){
      if (tagName === "◀") {
        clearTimeout(this.timeOut);
        let nodes = document.getElementById('sub-tag-bar').childNodes;
        for(var i=0; i<nodes.length; i++) {
            if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "auto";nodes[i].style.minWidth = "110px";}};
        setTimeout(function(){
          let nodes = document.getElementById('sub-sub-tag-bar').childNodes;
          for(var i=0; i<nodes.length; i++) {
              if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "0px";nodes[i].style.minWidth = "0px";};
            }},500);
        //makes the sub tag bar go down
        document.getElementById("sub-sub-tag-bar").classList.remove("sub-sub-tag-bar-active");
        
        document.getElementById("main-tag-bar").scrollLeft = -10000;
        

        //makes the sub sub tags small after going down
        setTimeout(() =>{
          let subnodes = document.getElementById('sub-sub-tag-bar').childNodes;
          for(var i=0; i<subnodes.length; i++) {
              if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "0px";subnodes[i].style.minWidth = "0px";};
              this.setState({subsubtagbar:false,subsubsubtagbar:false});
            }},500);


        nodes = document.getElementById('sub-tag-bar').childNodes;
        for(var x=0; x<nodes.length; x++) {
            if (nodes[x].nodeName.toLowerCase() === 'button') {nodes[x].style.width = "auto";nodes[x].style.minWidth = "110px";}};
          
      }else{
        let nodes = document.getElementById('sub-sub-sub-tag-bar').childNodes;
          for(var z=0; z<nodes.length; z++) {
              if (nodes[z].nodeName.toLowerCase() === 'button') {nodes[z].style.width = "auto";nodes[z].style.minWidth = "110px";};
            }

        if(this.state.subtags[0][this.state.filterSuperTag][this.state.tagSelection].series[this.state.brandSelection][tagName] !== false){
          document.getElementById("sub-sub-sub-tag-bar").classList.add("sub-sub-sub-tag-bar-active");
          document.getElementById("main-tag-bar").scrollLeft = -10000;
          this.timeOut = setTimeout(function(){
            let subnodes = document.getElementById('sub-sub-tag-bar').childNodes;
            for(var i=0; i<subnodes.length; i++) {
                if (subnodes[i].nodeName.toLowerCase() === 'button') {subnodes[i].style.width = "0px";subnodes[i].style.minWidth = "0px";};
              }},500);
              this.setState({subsubsubtagbar:true})
        }
        let filter = []
        for (let i = 0; i < this.state.sku.length; i++) {if(this.state.sku[i].gen === tagName && this.state.sku[i].brand === this.state.filterbrand && this.state.sku[i].tag === this.state.filterMainTag){filter.push(this.state.sku[i]);}};
        this.setState({filtersku : filter});this.setState({filtergen : tagName});
        if (filter.length === 0){filter.push({"name": false})}
        this.setState({filtersubsubsubtagbar : this.state.subtags[0][this.state.filterSuperTag][this.state.tagSelection].series[this.state.brandSelection][tagName]});
      }
  
  }
    subsubsubBar(tagName){
      if (tagName === "◀"){
        clearTimeout(this.timeOut);
        let nodes = document.getElementById('sub-sub-tag-bar').childNodes;
        for(var i=0; i<nodes.length; i++) {
            if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "auto";nodes[i].style.minWidth = "110px";}};
          
        setTimeout(() =>{
          let nodes = document.getElementById('sub-sub-sub-tag-bar').childNodes;
          for(var i=0; i<nodes.length; i++) {
              if (nodes[i].nodeName.toLowerCase() === 'button') {nodes[i].style.width = "0px";nodes[i].style.minWidth = "0px";};
              this.setState({subsubsubtagbar:false});
            }},500);
        document.getElementById("sub-sub-sub-tag-bar").classList.remove("sub-sub-sub-tag-bar-active");
        document.getElementById("main-tag-bar").scrollLeft = -10000;

      }else{
        let filter = []
        for (let i = 0; i < this.state.sku.length; i++) {if(this.state.sku[i].series === tagName && this.state.sku[i].gen === this.state.filtergen && this.state.sku[i].brand === this.state.filterbrand && this.state.sku[i].tag === this.state.filterMainTag){filter.push(this.state.sku[i]);}     };
        if (filter.length === 0){filter.push({"name": false})}
        this.setState({filtersku : filter});
      }
    }
    
    description(id){
      if(document.getElementById(id).classList.contains("description-active")){
      document.getElementById(id).classList.remove("description-active")
    }else{
      document.getElementById(id).classList.add("description-active")
    }
  }
    phoneValidity(){
      document.getElementById("phoneValiditySpan").innerText = ""
      let phoneNumber = document.getElementById("phoneNumber").value
      if(phoneNumber[0] !== "0"){
        document.getElementById("phoneValiditySpan").innerText = "\nرقم الهاتف يجب ان يبدأ بـ05 "
      }else if (phoneNumber.length < 10) {
        document.getElementById("phoneValiditySpan").innerText = "\nالرقم يجب ان يتكون من 10 خانات"
      }else if (phoneNumber.length > 10) {
        document.getElementById("phoneNumber").value = phoneNumber.slice(0, 10)
      }
    }

    paymentSelection(event){
      if(event === "direct-payment"){
        document.getElementById("payment-method-selection").value = "direct"
        document.getElementById("direct-payment").classList.add("payment-selection")
        document.getElementById("tamara-payment").classList.remove("payment-selection")
      }
      if(event === "tamara-payment"){
        document.getElementById("tamara-payment").classList.add("payment-selection-red")
        document.getElementById("direct-payment").classList.remove("payment-selection")
        setTimeout(function(){
          document.getElementById("tamara-payment").classList.remove("payment-selection-red")
          document.getElementById("direct-payment").classList.add("payment-selection")
        },600);
      }
    }

subMenu(){
      if (document.getElementById("shopping-bag-wrap").classList.contains("user-interface-active") === false && document.getElementById("user-interface").classList.contains("user-interface-active") === false && document.getElementById("shop-main").classList.contains("shop-main-overflow") === false){
        document.getElementById("user-interface").style.zIndex="100"
        document.getElementById("user-interface").classList.add("user-interface-active")
        document.getElementById("App-header").classList.add("shop-main-active")
        document.getElementById("App-header").classList.add("shop-main-overflow")
        document.getElementById("sub-menu-img").src = "imgs/x.png"
      }
      else if (document.getElementById("user-interface").classList.contains("user-interface-active") === true || document.getElementById("shopping-bag-wrap").classList.contains("user-interface-active") === true){
     
        document.getElementById("shop-main").style.height = `100%`  
        document.getElementById("user-interface").classList.remove("user-interface-active")
        document.getElementById("shopping-bag-wrap").classList.remove("user-interface-active")
        document.getElementById("App-header").classList.remove("shop-main-active")
        document.getElementById("sub-menu-img").src = "imgs/sub-menu.png"
        document.getElementById("user-interface").style.zIndex="2"
        document.getElementById("shopping-bag-wrap").style.zIndex="2"
    
        waitForElementTransition(document.getElementById("user-interface")).then(() => {
          document.getElementById("App-header").classList.remove("shop-main-overflow")
        });
      }
    }
    
   bagMenu(){
      if (document.getElementById("shopping-bag-wrap").classList.contains("user-interface-active") === false && document.getElementById("user-interface").classList.contains("user-interface-active") === false && document.getElementById("shop-main").classList.contains("shop-main-overflow") === false){
        
        if(window.innerWidth < 850){
          document.getElementById("mini-shopping-bag").classList.remove("mini-shopping-bag-active")
          setTimeout(() =>{
            document.getElementById("shop-main").style.height = `100vh`
          },500);
        }
    
   
        document.getElementById("shopping-bag-wrap").style.zIndex="100"
        document.getElementById("shopping-bag-wrap").classList.add("user-interface-active")
        document.getElementById("App-header").classList.add("shop-main-active") 
        document.getElementById("App-header").classList.add("shop-main-overflow")
        document.getElementById("sub-menu-img").src = "imgs/x.png"

        
        
        
      }
    }
    removeMiniShoppingBag(){
      document.getElementById("mini-shopping-bag").classList.remove("mini-shopping-bag-active")
      document.getElementById("shop-grid").classList.remove("shop-grid-active")
    }
    sorting(type){
      if(this.state.sorting === "lowHigh"){
      this.setState({
        sorting : "highLow",filtersku : this.state.filtersku.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      })
      }else{
        this.setState({
          sorting : "lowHigh",filtersku : this.state.filtersku.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
        })
      }
    }

      
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    render() {
      return (

        <div id="shop-main" className="shop-main">

<div className='nav-bar'>
            <img src={logo} className="App-logo" alt="logo" />

            <div id="Search-bag" className="Search-bag">
                <button className="shopping-bag-btn" onClick={() => this.bagMenu()}>
                    <img id='shopping-bag-img' className="shopping-bag-img" src="shopping-bag.svg" alt="" />
                    <span id="num-of-items" className="bag-items">0</span>
                </button>

                <button className="sub-menu-btn" onClick={() => this.subMenu()}>
                    <img id="sub-menu-img" className="sub-menu-img" src="imgs/sub-menu.png" alt="" />
                </button>
            </div>
            <div className='strip'></div>
        </div>
        <BannerImgs />
          <div id="user-interface" className="user-interface">
            <div className="inner-user-interface">
              
              <h3>شروط وسياسات المتجر:</h3>
              <ul>
                <li><a href="https://pcmod-sa.com/Terms-and-Conditions.html">الشروط والاحكام</a></li>
                <li><a href="https://pcmod-sa.com/Terms-and-Conditions.html#return">سياسة الارجاع</a></li>
                <li><a href="https://pcmod-sa.com/Terms-and-Conditions.html#privacy">سياسة الخصوصية</a></li>
              </ul>
              <h3>لأي ملاحظات يمكنكم التواصل معنا عبر:</h3>
              <ul>
                <li>البريد الاالكتروني:<br /><a href="mailto:info@pcmod-sa.com">info@pcmod-sa.com</a></li>
                <li>الواتساب:<br /><a href="https://wa.me/message/5C7XEKAATNDGN1">0502912596</a></li>
              </ul>
              <h3>تابعنا من خلال مواقع التواصل الاجتماعي:</h3>

              <div className="social">
                <a href="https://www.instagram.com/pcmod.sa/"><img src="icons/instagram.svg" alt="" /></a>
                <a href="https://twitter.com/PCMOD_SA"><img src="icons/twitter.svg" alt="" /></a>
                <a href="https://www.tiktok.com/@pcmod.sa"><img className="tiktok" src="icons/tiktok.svg" alt="" /></a>
                <a href="https://wa.me/message/5C7XEKAATNDGN1"><img src="icons/whatsapp.svg" alt="" /></a>
                <a href="https://t.snapchat.com/b0pPuLoa"><img src="icons/snap.jpeg" alt="" /></a>
              </div>
              <div className="footer">
                <div className="crn">
                  <p>مؤسسة التطوير الذكي للتجارة<br />سجل رقم: 1010818223<br />الرياض، حي السويدي الغربي</p>
                  <p></p>
                </div>
                <div class="vl"></div>
                <div className="links">
                  <p>روابط مهمة:</p>
                  <strong><a href="https://pcmod-sa.com/php/shipping-status.html">تتبع الطلبات</a></strong>
                  <br />
                  <strong><a href="https://pcmod-sa.com/pc-build-form/">لتصميم جهازك الخاص</a></strong>
                  <img alt="" src="imgs/payment_methed.png" />
                </div>
                <div className="copyright">© Pc Mod</div>
              </div>
            </div>
          </div>
          <div id="Search-bag" className="Search-bag">
            <input id="search" onKeyUp={() => this.search()} className="Search-bar" type="Search" placeholder="Search" />
            <button onClick={() => this.sorting("lowHigh")} className="sorting"><img className="icon" src="icons/sorting.png" alt="" /></button>

          </div>
            <div id="main-tag-bar" className="tag-bar">
              <div id="tag-bar">
                <button onClick={() => this.tag("ALL")} className="tag back-arrow">الكل</button>
                <button onClick={() => this.tag("COMPUTER")} className="tag"><img className="icon" src="icons/computer.svg" alt="" />&nbsp;&nbsp;&nbsp;اجهزة الكمبيوتر</button>
                <button onClick={() => this.tag("components")} className="tag"><img className="icon" src="icons/pc_parts.svg" alt="" />&nbsp;&nbsp;&nbsp;قطع الكمبيوتر</button>
                <button onClick={() => this.tag("pc_accessories")} className="tag"><img className="icon" src="icons/pc_accessories.svg" alt="" />&nbsp;&nbsp;&nbsp;ملحقات الكمبيوتر</button>
              </div>
              
              <div id="sub-main-tag-bar" className="sub-tag-bar">
              <button id="back-arrow" onClick={() => this.subMainTagBar("◀")} className="tag back-arrow"><i class="arrow left"></i></button>
                  {this.state.tagloaded &&
                      this.state.filtertag1.map((object, i) => {
                        return <button onClick={() => this.subMainTagBar(object[0])} className="tag"><img className="icon" src={object[1]} alt="" />&nbsp;&nbsp;&nbsp;{object[0]}</button>
                  })}
              </div>
              
              <div id="sub-tag-bar" className="sub-tag-bar sub1-tag-bar-active">
                <button onClick={() => this.subBar("◀")} className="tag back-arrow"><i class="arrow left"></i></button>
                  {this.state.tagloaded &&
                      this.state.filtertag.map((object, i) => {
                        return <button onClick={() => this.subBar(object)} className="tag">{object}</button>
                  })}
              </div>


              <div id="sub-sub-tag-bar" className="sub-tag-bar sub-tag-bar-active">
                <button onClick={() => this.subsubBar("◀")} className="tag back-arrow"><i class="arrow left"></i></button>
                {this.state.subsubtagbar &&
                    this.state.filtersubsubtagbar.map((object, i) => {
                      return <button onClick={() => this.subsubBar(object)} className="tag">{object}</button> 
                })}
              </div>
              

              <div id="sub-sub-sub-tag-bar" className="sub-tag-bar sub-tag-bar-active sub-sub-tag-bar-active">
                <button onClick={() => this.subsubsubBar("◀")} className="tag back-arrow"><i class="arrow left"></i></button>
                {this.state.subsubsubtagbar &&
                    this.state.filtersubsubsubtagbar.map((object, i) => {
                      return <button onClick={() => this.subsubsubBar(object)} className="tag">{object}</button> 
                })}
              </div>


            </div>

            <div id="shopping-bag-wrap" className="shopping-bag-wrap">
            <div id="shopping-bag" className="shopping-bag">
              <br /><br /><br />
              {
                  this.state.allBagItems.products.map((object, i) => {
                    return  <div loading="lazy" className="bag-grid">
                              <img loading="lazy" key={`bag-item-img-${object.SKU}`} className="bag-item-img skeleton-bag" src={object.img} alt="" />
                              <div className="shopping-bag-name-price-btn">
                                <p key={`bag-item-name-${object.name}`} ><b>{object.name}</b></p>
                                <p key={`bag-item-qty-${object.qty}`} >عدد: {object.qty}</p>
                                <p key={`bag-item-price-${object.price}`} >{Number(object.price).toLocaleString('en-US', {})} ﷼</p>
                                <button key={`bag-item-removeBtn-${object.SKU}`} onClick={() => this.removebagitems(i,object.img,object.name,object.price,object.SKU)} id={i} type="button" className="add-to-cart">ازالة</button>
                              </div>
                              <br /><br /><br />
                            </div>
                            
              })}
            <h2 id="cartTotal"></h2>
            <div className="checkout" id="checkout">
            <h2>الرجاء اختيار طريقة الدفع</h2>
            <hr /><br />
            <form className="checkout-form" action="./php/checkout.php" method="post">
              
              <div id="direct-payment" onClick={() => this.paymentSelection("direct-payment")} className="payment-methed payment-selection">
              <img alt="" src="imgs/payment_methed.png" />
              </div>

              <div id="tamara-payment" onClick={() => this.paymentSelection("tamara-payment")} className="payment-methed-tamara">
              <img alt="" src="imgs/tmara_logo.png" />
                <label style={{fontSize: "20px"}}>    قريباً  </label>
              </div>

              <input id="payment-method-selection" type="hidden" name="payment-method" value="direct" />


              <div>
              <label>الاسم الكامل: </label><br />
              <input required name="fullName" type="text" />
              </div>
              <div>
              <label>البريد الاكتروني: </label><br />
              <input required name="email" type="email" />
              </div>
              <div>
              <label>رقم الهاتف: <span style={{color: "red",fontSize: "12px"}} id="phoneValiditySpan"></span></label><br />
              <input required name="phone" pattern="\d*" onKeyUp={() => this.phoneValidity()} id="phoneNumber" type="number" placeholder="05XXXXXXXX" />
              </div>
              <div>
              <label>المنطقة: </label><br />
              <select required name="state" id="state">
                    <option value='01'>الرياض</option>
                    <option value='02'>مكة</option>
                    <option value='03'>المدينة المنورة</option>
                    <option value='04'>الشرقية</option>
                    <option value='05'>القصيم</option>
                    <option value='06'>حائل</option>
                    <option value='07'>تبوك</option>
                    <option value='08'>الحدود الشمالية</option>
                    <option value='09'>جازان</option>
                    <option value='10'>نجران</option>
                    <option value='11'>الباحة</option>
                    <option value='12'>الجوف</option>
                    <option value='14'>عسير</option>
                </select>
              </div>
              <div>
              <label>المدينة: </label><br />
              <input required name="city" type="text" />
              </div>
              <div>
              <label>اسم الحي: </label><br />
              <input required name="street" type="text" />
              </div>
              <div>
              <label>الرمز البريدي: </label><br />
              <input required name="zip" type="text" />
              </div>

              <input id="cartAmount" name="cartAmount" type="hidden" />
              <input id="cartDescription" name="cartDescription" type="hidden" />

              <button type="submit">الاستمرار للدفع</button>
              
            </form>
          <p style={{direction:"rtl"}}>*بالأستمرار للدفع انت توافق على <a href="https://pcmod-sa.com/Terms-and-Conditions.html">الشروط والاحكام</a></p> 
            </div>
            <br /><br /><br />
            </div>
            </div>

            <div id="shop-grid" className="shop-grid">
              {this.state.loaded &&
                this.state.filtersku.map((object, i) => {
                  return object.inStock === false ?
                              <div className="product-wrap" loading="lazy">
                                <img loading="lazy" key={`inStock-false-product-img-${object.SKU}`} className="product-img skeleton" src={object.img} alt="" />
                                <h4 key={`inStock-false-product-name-${object.name}`} className="product-name" >{object.name}</h4>
                                <div id={`${object.price}-description`} onClick={() => this.description(`${object.price}-description`)} dir="rtl" className="description">وصف المنتج ▼<br/>{object.description}</div>
                                <h4 key={`inStock-false-product-price-${object.price}`} className="outofstock">غير متوفر حالياً</h4>
                              </div>
                        
                        : object.name === false ?
                              <div className="not-available-product-wrap" loading="lazy">
                                <h1 key={`not-available-priducts${object.SKU}`} className="product-name column-span">لا يتوفر منتجات لهذه الفئة حالياً</h1>
                              </div>
                          
                        : object.discount !== "0" ? 

                            <div className="product-wrap" loading="lazy">
                              <img loading="lazy" key={`discount-true-product-img-${object.SKU}`} className="product-img skeleton" src={object.img} alt="" />
                              <h4 key={`discount-true-product-name-${object.name}`} className="product-name" >{object.name}</h4>
                              <div id={`${object.price}-description`} onClick={() => this.description(`${object.price}-description`)} dir="rtl" className="description">وصف المنتج ▼<br/>{object.description}</div>  
                              <h4 key={`discount-true-product-price-${object.price}`} dir="rtl" style={{textDecoration: "line-through",color: "red",marginBottom:"0px"}} >{Number(object.price).toFixed(2).toLocaleString('en-US', {})} ﷼</h4>
                              <h4 key={`discount-true-product-discount-${object.discount}`} dir="rtl" style={{marginTop: "0px"}} >{(Number(object.price)-Number(object.discount)).toFixed(2).toLocaleString('en-US', {})} ﷼</h4>
                              <button key={`discount-true-addToBag-btn-${object.SKU}`} onClick={() => this.bagitems(i,object.img,object.name,(Number(object.price)-Number(object.discount)),object.SKU)} id={"add"+i} type="button" className="add-to-cart">اضف الى السلة</button>
                          
                            </div>
                        
                        :

                              <div className="product-wrap" loading="lazy">
                                <img loading="lazy" key={`${object.SKU}product-img-${object.img}`} className="product-img skeleton" src={object.img} alt="" />
                                <h4 key={`${object.SKU}product-name-${object.name}`} className="product-name" >{object.name}</h4>
                                <div id={`${object.price}-description`} onClick={() => this.description(`${object.price}-description`)} dir="rtl" className="description">وصف المنتج ▼<br/>{object.description}</div>
                                <h4 key={`${object.SKU}product-price-${object.price}`} dir="rtl" >{Number(object.price).toFixed(2).toLocaleString('en-US', {})} ﷼</h4>
                                <button key={`${object.SKU}product-btn-${object.SKU}`} onClick={() => this.bagitems(i,object.img,object.name,object.price,object.SKU)} id={"add"+i} type="button" className="add-to-cart">اضف الى السلة</button>
                                
                              </div>
              })}
              <div id="mini-shopping-bag" className="mini-shopping-bag">
                <div onTouchMove={() => this.miniShoppingBagResetTimer()} className="inner-mini-shopping-bag">
                <div className="mini-shopping-bag-img-container-icon">
                  <div class="wrapper"> <svg id="checkmark" className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle id="checkmark__circle" className="" cx="26" cy="26" r="25" fill="none"/> <path id="checkmark__check" className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg></div>
                  <img key={`mini-shopping-bag-item-img-icon`} className="mini-bag-item-icon" src="mini-shopping-bag.svg" alt="" />
                </div>
                {
                  this.state.allBagItems.products.map((object, i) => {
                    return  <div className="mini-shopping-bag-item-container">
                      <div className="mini-shopping-bag-img-container">
                        <img key={`mini-shopping-bag-item-img-${object.SKU}`} className="mini-bag-item-img" src={object.img} alt="" />
                      </div>
                      <p key={`mini-shopping-bag-item-qty-${object.qty}`} >{object.qty}</p>
                    </div>
                  })}
                </div>
              </div>
            </div>
        </div>
    )}};

export default ShopProducts